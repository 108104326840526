<template>
  <div>
    <div class="das_top_cont">
      <div class="history_box account_input">
        <div class="das_top_cont">
          <ul
            class="das_top_list flex_center_between_box phoen_center_flex_warp"
            style="margin-bottom: 10px"
          >
            <li class="das_top_item">
              <div class="item_img color1 flex_center">
                <svg
                  t="1723008344135"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="5726"
                  width="2.28rem"
                  height="2.28rem"
                >
                  <path
                    d="M491.498324 330.013759H248.910213c-13.908626 0-25.107779 11.199153-25.107779 25.107779s11.199153 25.107779 25.107779 25.107779h242.588111c13.908626 0 25.107779-11.199153 25.107779-25.107779 0-13.727994-11.199153-25.107779-25.107779-25.107779zM491.498324 513.354736H248.910213c-13.908626 0-25.107779 11.199153-25.107779 25.107779s11.199153 25.107779 25.107779 25.10778h242.588111c13.908626 0 25.107779-11.199153 25.107779-25.10778 0-13.727994-11.199153-25.107779-25.107779-25.107779zM491.498324 697.237608H248.910213c-13.908626 0-25.107779 11.199153-25.107779 25.107779s11.199153 25.107779 25.107779 25.107779h242.588111c13.908626 0 25.107779-11.199153 25.107779-25.107779s-11.199153-25.107779-25.107779-25.107779zM794.778621 697.237608h-61.053449c-13.908626 0-25.107779 11.199153-25.107779 25.107779s11.199153 25.107779 25.107779 25.107779H794.778621c13.908626 0 25.107779-11.199153 25.107779-25.107779S808.506615 697.237608 794.778621 697.237608z"
                    p-id="5727"
                    fill="#1290C9"
                  ></path>
                  <path
                    d="M960.056447 901.712471h-35.403775V637.087317c0-43.17093-22.940201-83.99365-59.608396-106.391956l-209.532546-128.067737V196.88834c0-39.197037-17.882519-75.503969-49.131769-99.166696-31.24925-23.843359-70.80755-31.429882-108.740166-20.953255L190.566237 169.793614C136.918681 184.786029 99.347328 234.09843 99.347328 289.913565v611.798906H63.943553c-13.908626 0-25.107779 11.199153-25.107779 25.107779S50.034927 951.92803 63.943553 951.92803h895.932263c13.908626 0 25.107779-11.199153 25.107779-25.10778s-11.199153-25.107779-24.927148-25.107779z m-354.760275 0H149.562886V289.913565c0-33.416828 22.578938-63.040395 54.731346-71.891339L511.187158 124.997001c22.578938-6.141471 46.061034-1.625684 64.666079 12.644206C594.458282 151.730464 605.296172 173.406244 605.296172 196.88834v704.824131z m269.140942 0H655.331099V461.332863l183.340977 111.991533c22.037044 13.366731 35.584406 37.751984 35.584407 63.582289v264.805786z"
                    p-id="5728"
                    fill="#1290C9"
                  ></path>
                </svg>
              </div>

              <div class="item_group">
                <label class="item_lab">Total Number of Companies Searched</label>
                <div class="item_txt item_txtcolor1">{{totalSearched}}</div>
              </div>
            </li>
            <li class="das_top_item">
              <div class="item_img color2 flex_center">
                <svg
                  t="1723007964723"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="5427"
                  width="32"
                  height="32"
                >
                  <path
                    d="M186 201.8h654c8.6 0 15.6-7 15.6-15.6s-7-15.6-15.6-15.6H186c-8.6 0-15.6 7-15.6 15.6s7 15.6 15.6 15.6zM840 824.7H186c-8.6 0-15.6 7-15.6 15.6s7 15.6 15.6 15.6h654c8.6 0 15.6-7 15.6-15.6 0-8.7-7-15.6-15.6-15.6zM186 692.3c8.6 0 15.6-7 15.6-15.6v-327c0-8.6-7-15.6-15.6-15.6s-15.6 7-15.6 15.6v327c0 8.6 7 15.6 15.6 15.6zM840 334.2c-8.6 0-15.6 7-15.6 15.6v327c0 8.6 7 15.6 15.6 15.6s15.6-7 15.6-15.6v-327c0-8.7-7-15.6-15.6-15.6zM349.5 334.2c-8.6 0-15.6 7-15.6 15.6v253.6c-33.9 7.2-59.5 37.4-59.5 73.4 0 41.4 33.7 75.1 75.1 75.1 41.4 0 75.1-33.7 75.1-75.1 0-36-25.6-66.2-59.5-73.4V349.7c0-8.6-7-15.5-15.6-15.5z m43.9 342.5c0 24.2-19.7 44-43.9 44s-44-19.7-44-44c0-18.7 11.8-34.6 28.4-40.9 4.9-1.8 10.1-3 15.6-3s10.7 1.1 15.6 3c16.5 6.3 28.3 22.2 28.3 40.9zM497.4 349.7v327c0 8.6 7 15.6 15.6 15.6s15.6-7 15.6-15.6v-327c0-8.6-7-15.6-15.6-15.6-8.6 0.1-15.6 7-15.6 15.6zM676.5 692.3c8.6 0 15.6-7 15.6-15.6V423.1c33.9-7.2 59.5-37.4 59.5-73.4 0-41.4-33.7-75.1-75.1-75.1-41.4 0-75.1 33.7-75.1 75.1 0 36.1 25.6 66.2 59.5 73.4v253.6c0 8.6 7 15.6 15.6 15.6z m-43.9-342.6c0-24.2 19.7-44 43.9-44s44 19.7 44 44c0 18.7-11.8 34.7-28.4 41-4.9 1.8-10.1 3-15.6 3s-10.7-1.1-15.6-3c-16.5-6.3-28.3-22.2-28.3-41z"
                    fill="#55B419"
                    p-id="5428"
                  ></path>
                </svg>
              </div>

              <div class="item_group">
                <label class="item_lab"
                  >Total Number of Companies collected</label
                >
                <div class="item_txt item_txtcolor2">{{ totalOnlineDataCollected }}</div>
              </div>
            </li>
            <li class="das_top_item">
              <div class="item_img color3 flex_center">
                <svg
                  t="1723008388201"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="6026"
                  width="32"
                  height="32"
                >
                  <path
                    d="M918.565926 534.281481c-11.757037 0-21.333333-9.576296-21.333333-21.333333 0-211.721481-172.278519-384-384-384-146.868148 0-278.660741 81.73037-344.082963 213.333333-5.214815 10.524444-18.014815 14.885926-28.634074 9.576297-10.524444-5.214815-14.885926-18.014815-9.576297-28.634074C165.736296 253.155556 219.211852 193.991111 285.392593 152.082963 353.46963 109.037037 432.260741 86.281481 513.232593 86.281481c57.552593 0 113.493333 11.282963 166.115555 33.564445 50.820741 21.522963 96.426667 52.242963 135.585185 91.401481 39.158519 39.158519 69.973333 84.764444 91.401482 135.585186 22.281481 52.622222 33.564444 108.468148 33.564444 166.115555 0 11.757037-9.576296 21.333333-21.333333 21.333333z"
                    p-id="6027"
                    fill="#FFA000"
                  ></path>
                  <path
                    d="M918.660741 534.281481c-5.499259 0-10.903704-2.085926-15.075556-6.257777L797.392593 421.925926c-8.343704-8.343704-8.343704-21.807407 0-30.151111s21.807407-8.343704 30.151111 0l106.192592 106.192592c8.343704 8.343704 8.343704 21.807407 0 30.151112-4.171852 4.077037-9.671111 6.162963-15.075555 6.162962z"
                    p-id="6028"
                    fill="#FFA000"
                  ></path>
                  <path
                    d="M513.422222 939.614815c-57.552593 0-113.493333-11.282963-166.115555-33.564445-50.820741-21.522963-96.426667-52.242963-135.585186-91.401481-39.158519-39.158519-69.973333-84.764444-91.401481-135.585185-22.281481-52.622222-33.564444-108.562963-33.564444-166.115556 0-11.757037 9.576296-21.333333 21.333333-21.333333s21.333333 9.576296 21.333333 21.333333c0 211.721481 172.278519 384 384 384 146.868148 0 278.660741-81.73037 344.082963-213.333333 5.214815-10.524444 18.014815-14.885926 28.634074-9.576296 10.524444 5.214815 14.885926 18.014815 9.576297 28.634074C860.918519 772.740741 807.442963 831.905185 741.262222 873.813333 673.09037 916.859259 594.299259 939.614815 513.422222 939.614815z"
                    p-id="6029"
                    fill="#FFA000"
                  ></path>
                  <path
                    d="M214.091852 640.474074c-5.499259 0-10.903704-2.085926-15.075556-6.257778l-106.192592-106.192592c-8.343704-8.343704-8.343704-21.807407 0-30.151111s21.807407-8.343704 30.151111 0l106.192592 106.192592c8.343704 8.343704 8.343704 21.807407 0 30.151111-4.171852 4.171852-9.576296 6.257778-15.075555 6.257778z"
                    p-id="6030"
                    fill="#FFA000"
                  ></path>
                </svg>
              </div>
              <div class="item_group">
                <label class="item_lab">Total number of Offline Reports</label>
                <div class="item_txt item_txtcolor3">{{ totalDataUpdated }}</div>
              </div>
            </li>
          </ul>
        </div>

        <div class="accountright_cont_box account_input">
          <h2 class="history_box-head">My Usage</h2>
          <div class="flex_center_between_box" style="margin-top: 14px;">
            <div class="sel_box font_size_12">
              <el-input
                class="inline-input"
                clearable
                v-model="CategoryVal"
                suffix-icon="el-icon-search"
                placeholder="Enter company name"
              ></el-input>
              <!--  -->
            </div>
            <button class="btn_blue flex_center search_btn" @click="getgetUserActionLogPage()">
              <!-- <img src="../../assets/img/detail/btn_ic5.png" /> -->
              Search
            </button>
          </div>
          <div class="his_sel_box" style="width: 100%">
            <el-table class="spec_table table_height_30 table_font_size_14" :data="yourUsagelist" style="width: 100%" empty-text="No Data">
              <el-table-column prop="updateTime" label="Date" align="left"    show-overflow-tooltip>
             
              </el-table-column>
              <el-table-column
                prop="email"
                label="User Name"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span class="table-content">{{ scope.row.email }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="companyName"
                label="Company Name"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span class="table-content company_link" style="" @click="setid(scope.row)">
                    {{ scope.row.companyName }}
              
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="Status"
                label="Status"
                align="left"
              >
                <template slot-scope="scope">
                  <span class="table-content" v-if="scope.row.status==1">Finished</span>
                  <span class="table-content" v-else>In production</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="actionType"
                label="Usage Type"
                align="left"
                   show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span class="table-content" v-if="scope.row.actionType==0">Search</span>
                    <span class="table-content" v-if="scope.row.actionType==1">Company Details</span>
                      <span class="table-content" v-if="scope.row.actionType==2">Reprot Downloaded</span>
                        <span class="table-content" v-if="scope.row.actionType==3">Update Ordered</span>
                </template>
              </el-table-column>
            </el-table>
            <div v-if="page.pageSize<page.total" style="text-align: center; padding-top: 20px">
              <span class="contclass show_All" @click="apptablelist">Show All</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ReLogin :reLoginPop="reLoginVis"></ReLogin>
  </div>
</template>
<script>
import { mixins1 } from "@/mixins/index";
import { getUserActionLogPage,getmyDatabaseStatistics } from "@/api/userApi";
import ReLogin from "../../../components/ReLogin.vue";

export default {
  mixins: [mixins1],
  components: {
    ReLogin
  },
  data() {
    return {
      reLoginVis: false,
      value1: "",
      CategoryVal: "",
      totalDataUpdated:'',
      totalOnlineDataCollected:"",
      totalSearched:'',
      yourUsagelist: [],
      page: {
        pageSize: 10,
        pageNum: 1,
        total:'',
      },
      window: window,
    };
  },
  beforeDestroy() {},
  destroyed() {},
  mounted() {
    this.getgetUserActionLogPage();
  },

  methods: {
       // 点击跳转
       setid(row) {
        // sessionStorage.setItem("company", JSON.stringify(row));
        // let newscountry = JSON.parse(localStorage.getItem("countryList"))[0].countryList
          ;
        let companyCountryEn = "";
        // newscountry.map((item) => {
        //   if (item.countryCode == row.company_country) {
        //     DowJoneCode1 = item.dowjonesCountrycode;
        //   }
        // });
        let routeData = this.$router.resolve({
          path:
            "/index/company/detals/" +
            row.companyAaaid +
            "/background/snapshot?id3a=" +
            row.companyAaaid +
            "&companyCountry=" +
            ''+
            "&companyCountryEn=" +
            companyCountryEn,
        });

        window.open(routeData.href, "_blank");

    },
    getgetUserActionLogPage() {
      getUserActionLogPage({
        companyName: this.CategoryVal,
        startTime: "",
        endTime: "",
        typeList: [
          //必填写死
          1, 2, 3,
        ],
        page: this.page.pageNum,
        pageSize:this.page.pageSize,
      }).then((res) => {
        if (res.code == 200) {
          this.yourUsagelist = res.data.rows;
          this.page.total=res.data.total
          
        } else {
          this.yourUsagelist = [];
        }
      }).catch(err => {
        this.reLoginVis = String(err).includes('403')
      });
      getmyDatabaseStatistics().then(res=>{
        if(res.code==200){
          this.totalSearched=res.data.totalSearched
           this.totalOnlineDataCollected=res.data.totalOnlineDataCollected
            this.totalDataUpdated=res.data.totalDataUpdated
        }
        
      })
    },

    apptablelist() {
      this.page.pageSize= this.page.pageSize+5
   this.getgetUserActionLogPage()
    },
  },
};
</script>
<style scoped>
.flex_center_between_box {
  display: flex;
  justify-content: space-between;
}
.form-email {
  display: flex;
  width: 100%;
}
.block-con-body-text {
  word-break: keep-all;
  text-align: justify;
  font-size: 1rem;
  color: #022955;
}
.das_top_cont {
  /* margin-top: 3.5714285714285716rem; */
}

.item_txtcolor1 {
  color: #1290c9;
}
.item_txtcolor2 {
  color: #55b419;
}
.item_txtcolor3 {
  color: #ffa000;
}
.das_top_item .color1 {
  background: rgba(18, 144, 201, 0.12);
}
.das_top_item .color2 {
  background: rgba(85, 180, 25, 0.12);
}
.das_top_item .color3 {
  background: rgba(255, 160, 0, 0.12);
}
.das_top_item .color4 {
  background: #ff7600;
}
.das_top_item .item_img img {
  width: 1.2857142857142858rem;
  height: 1.2857142857142858rem;
}
.das_top_item .item_title {
  font-size: 1.2857142857142858rem;
  color: #022955;
  margin: 0.7142857142857143rem 0 0.7142857142857143rem;
  text-align: left;
}
.das_top_item .item_group {
  margin-left: 0.7142857142857143rem;
  font-size: 1.2857142857142858rem;

  /* width: 17.142857142857142rem; */
}
.das_top_item .item_lab {
  display: block;
  color: #1a2332;

  margin-top: 0.35714285714285715rem;
}
.item_txt {
  font-size: 2.142857142857143rem;
  text-align: center;
}
.cont_m {
  margin-top: 2.142857142857143rem;
  margin-bottom: 5.714285714285714rem;
}

.country_box {
  margin-top: 4.285714285714286rem;
}

.country_box .title {
  margin-bottom: 0;
}

.title {
  margin-bottom: 1.4285714285714286rem;
}

.title_txt {
  font-size: 1.1428571428571428rem;
  font-family: 'DMSans Bold';
}

.title_txt img {
  width: 1.4285714285714286rem;
  margin-right: 0.5714285714285714rem;
}

.c_d_buttom {
  justify-content: flex-end;
  /* margin-top: 1.4285714285714286rem; */
  margin-bottom: 0;
}

.btn_blue {
  padding: 0.7142857142857143rem 1.4285714285714286rem;
  background: #1290c9;
  font-size: 1.2857142857142858rem;
  color: #ffffff;
  cursor: pointer;
}

.btn_blu {
  margin-right: 1.4285714285714286rem;
}

.btn_blue img {
  width: 1.2857142857142858rem;
  height: 1.2857142857142858rem;
  margin-right: 0.42857142857142855rem;
}
.sel_box /deep/ .el-input__inner {
  border-color: #ccc;
  border-radius: 0.5714285714285714rem;
  font-size: 1.1428571428571428rem;
}



.das_top_cont {
  /* margin-top: 3.5714285714285716rem; */
}

.das_top_item {
  width: 32.5%;
  padding: 1.4285714285714286rem;
  /* border-radius: 1rem; */
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0rem 0.14285714285714285rem 0.2857142857142857rem 0rem rgba(0, 0, 0, 0.12);
}

.das_top_item .item_img {
  width: 4.285714285714286rem;
  height: 4.285714285714286rem;
  line-height: 5.571428571428571rem;
  text-align: center;
  border-radius: 50%;
}

.das_top_item .item_title {
  font-size: 1.2857142857142858rem;
  color: #022955;
  margin: 0.7142857142857143rem 0 0.7142857142857143rem;
  text-align: center;
}

.top_tab {
  margin-top: 2.857142857142857rem;
}
/deep/ .el-table {
  margin-top: 1.1428571428571428rem;
}
/deep/ .el-table th.el-table__cell {
  background-color: #f2f5f9 !important;
}
.jiange {
  color: #cccccc;
  margin: 0 1.4285714285714286rem;
  font-size: 1.4285714285714286rem;
  /* font-size: 1.25em; */
}
.contclass {
  font-size: 1.4285714285714286rem;
  color: #1290c9;
  font-style: normal;
}
/deep/ .el-table .cell {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  /* line-height: 2.142857142857143rem; */
}
/deep/ .el-table th.el-table__cell > .cell {
  font-family: 'DMSans Bold';
}
.last-colmun {
  padding: 2rem 0rem;
  border-bottom: 0.07142857142857142rem solid #ebeef5;
}
.dingwei {
  position: absolute;
  top: -2.142857142857143rem;
  right: 0rem;
}
/deep/ .el-descriptions-item__cell {
  padding-bottom: 2.142857142857143rem !important;
}
/deep/ .avatar-uploader .el-upload {
  border: 0.07142857142857142rem dashed #d9d9d9;
  border-radius: 0.42857142857142855rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 1.4285714285714286rem;
  color: #8c939d;
  width: 5rem;
  height: 5rem;
  line-height: 5rem;
  text-align: center;
}
/deep/ .avatar {
  width: 5rem;
  height: 5rem;
  display: block;
}
@media screen and (max-width: 1441px) {
  .btn_blue {
    font-size: 14px;
    width: max-content;
  }
  .item_lab{
    font-size: 14px;
  }
}
</style>